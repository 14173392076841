/**
 * Catch events and return them. But also return the options without the events back
 *
 * @param options
 * @returns {{options, events: *[]}}
 */
export function catchEvents (options) {
  const events = []

  Object.entries(options).forEach(([index, v]) => {
    if (typeof v === 'string' && index.match('event_')) {
      events.push({
        name: index.replace('event_', ''),
        value: v,
      })
      delete options[index]
    }
  })

  return {
    events,
    options,
  }
}

export function executeEvent (events, feature) {
  //add events from the option array
  events.forEach(event => {
    feature.on(event.name, function () {
      //because we get the data as string, we need eval()
      eval(event.value)
    })
  })
}
