export const apiFitMap = (map, options) => {
  if (map) {
    options.type = options.type || ''

    switch (options.type) {
      case 'markers':
      case 'marker':
        map.map.fitBounds(map.bounds.markers)
        break
      case 'circle':
      case 'circles':
        map.map.fitBounds(map.bounds.circles)
        break
      case 'rectangle':
      case 'rectangles':
        map.map.fitBounds(map.bounds.rectangles)
        break
      case 'polylines':
      case 'polyline':
      case 'route': //routes are infact polylines
      case 'routes': //routes are infact polylines
        map.map.fitBounds(map.bounds.polylines)
        break
      case 'polygons':
      case 'polygon':
        map.map.fitBounds(map.bounds.polygons)
        break

      default:
        map.map.fitBounds({
          ...map.bounds.markers,
          ...map.bounds.circles,
          ...map.bounds.rectangles,
          ...map.bounds.polylines,
          ...map.bounds.polygons,
        })
        break
    }
  }
}
