import L from 'leaflet'
import 'leaflet.gridlayer.googlemutant'

export const addGoogleTile = (tile, googleStyledMap) => {
  const tileLayer = L.gridLayer.googleMutant({
    type: tile.name.replace('google_maps_', ''),
    styles: googleStyledMap,
  })

  tileLayer.name = tile.name
  tileLayer.label = tile.label || tile.name

  return tileLayer
}
