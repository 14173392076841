export const apiGetCircle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const circle = self.searchCircle(mapID, options.key)

  //is there a map
  if (map && circle !== undefined) {
    return circle
  }
}
