import L from 'leaflet'

export const arrayToLatLng = (coords) => {
  const new_coords = []

  Object.entries(coords).forEach(([, val]) => {
    if (typeof val === 'string') {
      val = val.split(',')
    }

    const lat = parseFloat(val[0].trim())
    const lng = parseFloat(val[1].trim())

    new_coords.push(L.latLng(lat, lng))
  })

  return new_coords
}
