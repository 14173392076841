import { arrayToLatLng, cleanArray, base64Decode } from '../../utils'
import L from 'leaflet'
import 'leaflet-openweathermap'
import 'leaflet-fullscreen'
import 'leaflet-pegman/leaflet-pegman'
import 'leaflet.markercluster'
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.setMap = function (options) {
  //merge default settings with given settings
  options = {
    'selector': '',
    'tiles': '',
    'tile_control_position': '',
    'zoom': '',
    'max_zoom': null,
    'min_zoom': null,
    'zoom_control': true,
    'zoom_control_position': '',
    'center': '',
    'width': '',
    'height': '',
    'scroll_wheel': true,
    'scale_control': true,
    'scale_control_position': '',
    'google_styled_map': '',
    'marker_cluster': {},
    'fit_map': {},
    'focus_current_location': false,
    'gesture_handling': true,
    'weather': {},
    'googleStreetview': false,
    'crs': 'default',
    'fullscreenControl': false,
    ...options,
  }

  const self = this

  const raw_options = { ...options }
  const map = {}

  //default vars
  let lat = self.def.lat
  let lng = self.def.lng

  // remove the # from the selector
  options.selector = options.selector.replace('#', '')

  //turn back the address, input_address and latlng
  options.center = base64Decode(options.center)
  options.center = options.center.toString()

  //override center by setting the center or zoom level
  if (options.center !== undefined && options.center !== '') {
    options.center = options.center.split(',')

    if (options.center.length === 1) {
      options.center = options.center[0].split('|')
    }

    const center = arrayToLatLng(cleanArray([options.center]))

    lat = center[0].lat
    lng = center[0].lng
  }

  //set the width for the div
  const element = document.getElementById(options.selector)
  element.style.width = options.width
  element.style.height = options.height

  // set the zoom
  const zoomOptions = {}
  if (options.min_zoom !== null) {
    zoomOptions.minZoom = options.min_zoom
  }
  if (options.max_zoom !== null) {
    zoomOptions.maxZoom = options.max_zoom
  }

  //fire up the map
  map.map = L.map(options.selector, {
    fullscreenControl: options.fullscreenControl,
    crs: self.crs.get(options.crs),
    center: [lat, lng],
    ...zoomOptions,
    zoom: options.zoom,
    zoomControl: options.zoom_control,
    scrollWheelZoom: options.scroll_wheel,
    gestureHandling: options.gesture_handling,
  })

  // or, add to an existing map:
  // map.map.addControl(L.Control.Fullscreen());

  //save the raw options
  map.raw_options = raw_options

  //set the positions of the zoom control
  if (options.zoom_control) {
    map.map.zoomControl.setPosition(options.zoom_control_position)
  }

  map.scaleControl = null
  if (options.scale_control) {
    map.scaleControl = L.control.scale({
      position: options.scale_control_position,
    }).addTo(map.map)
  }

  self.addTiles(map, options.tiles, options.google_styled_map, options.tile_control_position)

  //init weather layers
  map.weatherLayers = {}

  if (options.weather.layers.length > 0) {
    options.weather.layers.forEach(value => {

      //create the layer
      if (typeof L.OWM[value.name] === 'function') {
        map.weatherLayers[value.name] = L.OWM[value.name].call(null, {
          showLegend: value.legend,
          opacity: value.opacity,
          appId: base64Decode(options.weather.api),
        })
      }

      //add the layer
      map.map.addLayer(map.weatherLayers[value.name])
    })
  }

  //focus on the users current location
  if (options.focus_current_location) {
    self.geolocate({
      success: function (pos) {
        map.map.panTo([pos.coords.latitude, pos.coords.longitude])
      },
    })
  }

  if (options.googleStreetview) {
    const pegmanControl = new L.Control.Pegman({
      position: 'bottomright', // position of control inside the map
      theme: 'leaflet-pegman-v3-default', // or "leaflet-pegman-v3-small"
    })
    pegmanControl.addTo(map.map)
  }

  //save the marker cluster data
  map.marker_cluster = options.marker_cluster

  //init the cluster class if needed
  if (options.marker_cluster.enabled) {
    map.marker_cluster.markers = L.markerClusterGroup({
      showCoverageOnHover: map.marker_cluster.showCoverageOnHover,
      zoomToBoundsOnClick: map.marker_cluster.zoomToBoundsOnClick,
      spiderfyOnMaxZoom: map.marker_cluster.spiderfyOnMaxZoom,
      removeOutsideVisibleBounds: map.marker_cluster.removeOutsideVisibleBounds,
    })
  }

  // bound stuff, will handle by the API
  map.bounds = {}
  map.bounds.routes = [] //routes are infact polylines
  map.bounds.markers = []
  map.bounds.circles = []
  map.bounds.rectangles = []
  map.bounds.polylines = []
  map.bounds.polygons = []
  map.bounds.geojson = []

  //set the fit
  map.fit = {}
  map.fit.routes = options.fit_map.routes //routes are infact polylines
  map.fit.markers = options.fit_map.markers
  map.fit.circles = options.fit_map.circles
  map.fit.rectangles = options.fit_map.rectangles
  map.fit.polylines = options.fit_map.polylines
  map.fit.polygons = options.fit_map.polygons
  map.fit.geojson = options.fit_map.geojson
  map.fit.forceZoomOut = options.fit_map.forceZoomOut
  map.fit.forceZoom = options.fit_map.forceZoom

  //Add google like overlay
  self.addOverlay(map, options)

  //save to the global object
  self.maps.set(options.selector, map)

  //callback function when all things is ready
  setTimeout(() => self.fire('maps.ready'), 500)
}
