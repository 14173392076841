import { base64Decode, strToBool } from '../../../utils'
import L from 'leaflet'
import $ from 'domtastic'

export const apiUpdateMap = (map, options) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    if (options.center !== undefined) {
      let center = base64Decode(options.center)
      center = center.split(',')
      map.map.setView([center[0], center[1]])
      map.raw_options.center = options.center
      delete options.center
    }

    if (options.zoom !== undefined) {
      map.map.setZoom(options.zoom)
      map.raw_options.zoom = options.zoom
      delete options.zoom
    }

    if (options.max_zoom) {
      map.map.setMaxZoom(options.max_zoom)
      map.raw_options.max_zoom = options.max_zoom
      delete options.max_zoom
    }

    if (options.scroll_wheel) {
      if (strToBool(options.scroll_wheel)) {
        map.map.scrollWheelZoom.enable()
      }
      else {
        map.map.scrollWheelZoom.disable()
      }

      map.raw_options.scroll_wheel = strToBool(options.scroll_wheel)
      delete options.scroll_wheel
    }

    if (options.zoom_control) {
      if (strToBool(options.zoom_control)) {
        map.map.zoomControl.enable()
      }
      else {
        map.map.zoomControl.disable()
      }

      map.raw_options.zoom_control = strToBool(options.zoom_control)
      delete options.zoomControl
    }

    if (options.zoom_control_position) {
      if (self.positions.indexOf(options.zoom_control_position) !== -1) {
        map.map.zoomControl.setPosition(options.zoom_control_position)
        map.raw_options.zoom_control_position = options.zoom_control_position
        delete options.zoom_control_position
      }
    }

    if (options.scale_control) {
      if (strToBool(options.scale_control) && map.scaleControl === null) {
        map.scaleControl = L.control.scale({
          position: map.raw_options.scale_control_position,
        }).addTo(map.map)

      }
      else if (!strToBool(options.scale_control) && map.scaleControl !== null) {
        map.scaleControl.remove()
        map.scaleControl = null
      }

      map.raw_options.scale_control = strToBool(options.scale_control)
      delete options.scale_control
    }

    if (options.scale_control_position) {
      if (map.scaleControl !== null) {
        map.scaleControl.setPosition(options.scale_control_position)
      }

      map.raw_options.scale_control_position = options.scale_control_position
      delete options.scale_control_position
    }

    if (options.overlay_html) {
      self.updateOverlay(map, {
        overlay_html: options.overlay_html,
        overlay_position: options.overlay_position,
      })

      map.raw_options.overlay_html = options.overlay_html
      map.raw_options.overlay_position = options.overlay_position
      delete options.overlay_html
      delete options.overlay_position
    }

    //update the widths
    if (options.height || options.width) {
      //get the container
      const $container = $(map.map.getContainer())

      //update width
      if (options.width) {
        $container.css('width', options.width)
      }

      //update height
      if (options.height) {
        $container.css('height', options.height)
      }

      //redraw the map
      map.map.invalidateSize()
    }

    if (options.tiles && options.tiles !== '' && options.tiles !== null) {
      //remove the current ones
      Object.values(map.tiles).forEach(tile => tile.remove())

      //remove the controlTile if needed
      if (map.tileControl !== null) {
        map.tileControl.remove()
      }

      //add the new ones
      self.addTiles(map, options.tiles)
    }
  }
}
