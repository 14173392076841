export const apiGetMarker = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  let marker

  if (options.multiple !== undefined && options.multiple) {
    marker = self.searchMarker(mapID, options.key, true)
  }
  else {
    marker = self.searchMarker(mapID, options.key)
  }

  //is there a map
  if (map && marker !== undefined) {
    //remove from map
    return marker
  }
}
