//----------------------------------------------
//update a google like overlay like the iframe
//----------------------------------------------
import { ReinosMaps } from '../../core'
import $ from 'domtastic'

ReinosMaps.prototype.updateOverlay = function (map, options) {
  const self = this

  //default
  map.overlay = map.overlay || {}
  map.overlay.enabled = !!map.overlay.enabled
  map.overlay.position = options.overlay_position !== '' ? options.overlay_position : map.overlay.position
  map.overlay.html = options.overlay_html !== '' ? options.overlay_html : map.overlay.html

  //no overlay?
  if ($(map.raw_options.selector).find('.google-like-overlay-position').length === 0) {
    self.addOverlay(map, options)
  }

  if (map.overlay.html !== undefined) {
    if (map.overlay.html === '') {
      self.removeOverlay(map, options)
    }
    else {
      $(map.raw_options.selector).find('.google-like-overlay-content').html(map.overlay.html)
    }
  }

  if (map.overlay.position !== undefined) {
    $(map.raw_options.selector).find('.google-like-overlay-position').css('left', '').css('right', '')
    $(map.raw_options.selector).find('.google-like-overlay-position').css(map.overlay.position, '0px')
  }
}
