export const apiHideMarker = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const marker = self.searchMarker(mapID, options.key)

  //is there a map
  if (map && marker !== undefined) {
    marker.closePopup()
    marker.closeTooltip()
    marker._icon.style.display = 'none'
    marker._shadow.style.display = 'none'
  }
}
