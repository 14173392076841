import { base64Decode } from './base64'

export function decodeContent (content) {
  //convert, if the content is base64 encoded
  const isBase64Encoded = content && content.startsWith('base64:')

  return isBase64Encoded
    ? base64Decode(content.replace('base64:', ''))
    : content
}
