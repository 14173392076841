import L from 'leaflet'
import { ReinosMaps } from '../core'
import { decodeContent } from '../utils'

ReinosMaps.prototype.initPopup = function (feature, popupOptions) {
  //merge default settings with given settings
  popupOptions = {
    //Popup default
    'maxWidth': '300',
    'minWidth': '50',
    'maxHeight': null,
    'autoPan': true,
    'autoPanPaddingTopLeft': null,
    'autoPanPaddingBottomRight': null,
    'autoPanPadding': [5, 5],
    'keepInView': false,
    'closeButton': true,
    'autoClose': true,
    'closeOnEscapeKey': true,
    'closeOnClick': '*',
    'className': '',
    'popupContent': '',
    ...popupOptions,
  }

  //convert, if the content is base64 encoded
  popupOptions.popupContent = decodeContent(popupOptions.popupContent);

  //add popup to the feature if there is any content
  if (popupOptions.popupContent && popupOptions.popupContent !== '') {
    const popup = L.popup(popupOptions).setContent(popupOptions.popupContent)
    feature.bindPopup(popup)
  }

  return feature
}
