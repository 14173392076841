import { decodeContent } from '../../../utils'

export const apiUpdateRectangle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  let rectangle = self.searchRectangle(mapID, options.key)

  //is there a map
  if (map && rectangle !== undefined) {
    //remove key
    delete options.key

    //set the new user options
    rectangle.setUserOptions(options)

    //update tooltip
    if (options.tooltip !== undefined) {
      //set the tooltip
      self.updateTooltip(options.tooltip, rectangle)
      delete options.tooltip
    }

    //if latlngs is found in the options, use that for redraw the line
    if (typeof options.latlngs !== 'undefined') {
      rectangle.setLatLngs(options.latlngs)
      delete options.latlngs
    }

    //set the new options
    rectangle.setStyle(options)

    //set the popup content
    if (options.popup !== undefined && options.popup.popupContent !== undefined) {

      if (rectangle.getPopup() === undefined) {
        //init the popup
        rectangle = self.initPopup(rectangle, options.popup)
      }
      else {
        const decodedContent = decodeContent(options.popup.popupContent)
        rectangle.getPopup().setContent(decodedContent)
      }

      delete options.popup.popupContent
    }

    return rectangle
  }
}
