import { ReinosMaps } from '../core'

class Event {
  queue = {}
  fired = []

  fire (event) {
    const queue = this.queue[event]

    if (typeof queue === 'undefined') {
      return
    }

    while (queue.length) {
      (queue.shift())()
    }

    this.fired[event] = true
  }

  on (event, callback) {
    if (this.fired[event] === true) {
      return callback()
    }

    if (typeof this.queue[event] === 'undefined') {
      this.queue[event] = []
    }

    this.queue[event].push(callback)
  }

}

const _event = new Event()

// Expose the events
ReinosMaps.prototype.on = function (event, callback) {
  _event.on(event, callback)
}

ReinosMaps.prototype.fire = function (event) {
  _event.fire(event)
}
