import { createUUID } from '../../../utils'

const search = (type, options, baseUrl) => {
  const sessionKey = createUUID()
  return fetch(`${baseUrl}&type=${type}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `input=${options[type]}`,
  }).then(res => res.json()).then(result => {
    if (options.callback && typeof (options.callback) === 'function') {
      options.callback(result, type, sessionKey)
    }
  })
}

export const apiGeocode = (options) => {
  if (options.latlng !== undefined) {
    search('latlng', options, window.REINOS_MAPS.api_path)
  }

  if (options.address !== undefined) {
    search('address', options, window.REINOS_MAPS.api_path)
  }

  if (options.ip !== undefined) {
    search('ip', options, window.REINOS_MAPS.api_path)
  }
}
