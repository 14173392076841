import { decodeContent } from '../../../utils'

export const apiUpdateCircle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  let circle = self.searchCircle(mapID, options.key)

  //is there a map
  if (map && circle !== undefined) {
    //remove key
    delete options.key

    //set the new user options
    circle.setUserOptions(options)

    //update tooltip
    if (options.tooltip !== undefined) {
      //set the tooltip
      self.updateTooltip(options.tooltip, circle)
      delete options.tooltip
    }

    //if latlngs is found in the options, use that for redraw the line
    if (typeof options.latlng !== 'undefined') {
      circle.setLatLng(options.latlngs)
      delete options.latlng
    }

    //update radius
    if (typeof options.radius !== 'undefined') {
      circle.setRadius(options.radius)
      delete options.radius
    }

    //set the new options
    circle.setStyle(options)

    //set the popup content
    if (options.popup !== undefined && options.popup.popupContent !== undefined) {

      if (circle.getPopup() === undefined) {
        //init the popup
        circle = self.initPopup(circle, options.popup)
      }
      else {
        const decodedContent = decodeContent(options.popup.popupContent)
        circle.getPopup().setContent(decodedContent)
      }

      delete options.popup.popupContent
    }

    return circle
  }
}
