export const apiRemoveMarkers = (map, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS
  const cachedMarkers = self.markers.get(mapID)

  if (map && cachedMarkers !== undefined) {
    //remove markers
    cachedMarkers.forEach(({ marker }) => map.map.removeLayer(marker))

    //also removing from the cluster if needed
    if (map.marker_cluster.enabled) {
      map.marker_cluster.markers.clearLayers()
    }

    //reset marker cache
    self.markers.set(mapID, [])

    //reset the marker bounds
    map.bounds.markers = []
  }
}
