export const apiRemovePolygon = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const polygon = self.searchPolygon(mapID, options.key)

  if (map && polygon !== undefined) {
    polygon.remove()

    //remove from the cache
    self.removePolygon(mapID, options.key)

    polygon.getLatLngs().forEach(latlngs => {
      latlngs.forEach(latlng => {
        map.bounds.polygons = map.bounds.polygons.filter(val => {
          return val[0] !== latlng.lat && val[1] !== latlng.lng
        })
      })
    })
  }
}
