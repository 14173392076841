export const apiToggleMarkerPopup = (options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const marker = self.searchMarker(mapID, options.key)

  //close infoWindow
  if (marker !== undefined) {
    marker.togglePopup()
  }
}
