export const apiHideMarkers = (map, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS
  const cachedMarkers = self.markers.get(mapID)

  if (map && cachedMarkers !== undefined) {
    cachedMarkers.forEach(({ marker }) => {
      //remove from map
      marker.closePopup()
      marker.closeTooltip()
      marker._icon.style.display = 'none'
      marker._shadow.style.display = 'none'
    })
  }
}
