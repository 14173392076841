export const apiRemoveMarker = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const marker = self.searchMarker(mapID, options.key)

  if (map && marker !== undefined) {
    //remove the marker
    map.map.removeLayer(marker)

    //remove from the cache
    self.removeMarker(mapID, options.key)

    //also removing from the cluster if needed
    if (map.marker_cluster.enabled) {
      map.marker_cluster.markers.removeLayer(marker)
    }

    //remove from the bounds
    map.bounds.markers = map.bounds.markers.filter(
      value => marker.getLatLng().lat !== value[0] && marker.getLatLng().lng !== value[1])
  }
}
