import L from 'leaflet'

export const apiAddGeoJson = (map, options) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    //is there a dynamic given function for the styling?
    /*
    REINOS_MAPS.setGeoJsonStyle(function(feature) {
        switch (feature.properties.party) {
            case 'Republican': return {color: "#ff0000"};
            case 'Democrat':   return {color: "#0000ff"};
        });
    */
    if (typeof self._geojson.style === 'function') {
      options.style = self._geojson.style
    }

    //call the geoJson function
    L.geoJSON(options.json, {
      style: options.style,
      onEachFeature: function (feature, layer) {
        //enable the popup
        if (feature.properties && feature.properties.popupContent) {
          layer.bindPopup(feature.properties.popupContent)
        }

        //enable the tooltip
        if (feature.properties && feature.properties.name) {
          layer.bindTooltip(feature.properties.name)
        }

        //save the bounds
        if (typeof layer.getLatLngs === 'function') {
          layer.getLatLngs().flat().forEach(location => {
            map.bounds.geojson.push([location.lat, location.lng])
          })
        }
        else {
          const location = layer.getLatLng()
          map.bounds.geojson.push([location.lat, location.lng])
        }

      },
    }).addTo(map.map)

    //fit map
    self.fitBounds(map)
  }
}
