export const apiRemovePolyline = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const polyline = self.searchPolyline(mapID, options.key)

  if (map && polyline !== undefined) {
    polyline.remove()

    //remove from the cache
    self.removePolyline(mapID, options.key)

    //rebuild the bounds
    polyline.getLatLngs().forEach(latlng => {
      map.bounds.polylines = map.bounds.polylines.filter(val => {
        return val[0] !== latlng.lat && val[1] !== latlng.lng
      })
    })
  }
}
