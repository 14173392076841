import L from 'leaflet'
import axios from 'axios'

L.KML = L.FeatureGroup.extend({
  options: {},

  initialize: function (kml, options) {
    L.Util.setOptions(this, options)
    this._kml = kml
    this._layers = {}

    if (kml) {
      this.addKML(kml, options)
    }
  },

  loadXML: function (url, cb, options) {
    if (options === undefined) {
      options = this.options
    }

    axios.get(url, {
      responseType: 'xml',
    }).then(function (response) {
      cb(response.request.responseXML, options)
    })
  },

  addKML: function (url, options) {
    const _this = this
    const cb = function (gpx, options) {
      _this._addKML(gpx, options)
    }
    this.loadXML(url, cb, options)
  },

  _addKML: function (xml) {
    const layers = L.KML.parseKML(xml)
    if (!layers || !layers.length) {
      return
    }
    for (let i = 0; i < layers.length; i++) {
      this.fire('addlayer', {
        layer: layers[i],
      })
      this.addLayer(layers[i])
    }
    this.latLngs = L.KML.getLatLngs(xml)
    this.fire('loaded')
  },

  latLngs: [],
})

L.Util.extend(L.KML, {

  parseKML: function (xml) {
    const style = this.parseStyle(xml)
    let el = xml.getElementsByTagName('Folder')
    const layers = []
    let l
    for (let i = 0; i < el.length; i++) {
      if (!this._check_folder(el[i])) {
        continue
      }
      l = this.parseFolder(el[i], style)
      if (l) {
        layers.push(l)
      }
    }
    el = xml.getElementsByTagName('Placemark')
    for (let j = 0; j < el.length; j++) {
      if (!this._check_folder(el[j])) {
        continue
      }
      l = this.parsePlacemark(el[j], xml, style)
      if (l) {
        layers.push(l)
      }
    }
    return layers
  },

  // Return false if e's first parent Folder is not [folder]
  // - returns true if no parent Folders
  _check_folder: function (e, folder) {
    e = e.parentElement
    while (e && e.tagName !== 'Folder') {
      e = e.parentElement
    }
    return !e || e === folder
  },

  parseStyle: function (xml) {
    const style = {}
    const sl = xml.getElementsByTagName('Style')

    //for (var i = 0; i < sl.length; i++) {
    const attributes = {
      color: true,
      width: true,
      Icon: true,
      href: true,
      hotSpot: true,
    }

    function _parse (xml) {
      const options = {}
      for (let i = 0; i < xml.childNodes.length; i++) {
        const e = xml.childNodes[i]
        const key = e.tagName
        if (!attributes[key]) {
          continue
        }
        if (key === 'hotSpot') {
          for (let j = 0; j < e.attributes.length; j++) {
            options[e.attributes[j].name] = e.attributes[j].nodeValue
          }
        }
        else {
          const value = e.childNodes[0].nodeValue
          if (key === 'color') {
            options.opacity = parseInt(value.substring(0, 2), 16) / 255.0
            options.color = '#' + value.substring(2, 8)
          }
          else if (key === 'width') {
            options.weight = value
          }
          else if (key === 'Icon') {
            const ioptions = _parse(e)
            if (ioptions.href) {
              options.href = ioptions.href
            }
          }
          else if (key === 'href') {
            options.href = value
          }
        }
      }
      return options
    }

    for (let i = 0; i < sl.length; i++) {
      const e = sl[i]
      let el
      let options = {},
        poptions = {},
        ioptions = {}
      el = e.getElementsByTagName('LineStyle')
      if (el && el[0]) {
        options = _parse(el[0])
      }
      el = e.getElementsByTagName('PolyStyle')
      if (el && el[0]) {
        poptions = _parse(el[0])
      }
      if (poptions.color) {
        options.fillColor = poptions.color
      }
      if (poptions.opacity) {
        options.fillOpacity = poptions.opacity
      }
      el = e.getElementsByTagName('IconStyle')
      if (el && el[0]) {
        ioptions = _parse(el[0])
      }
      if (ioptions.href) {
        // save anchor info until the image is loaded
        options.icon = new L.KMLIcon({
          iconUrl: ioptions.href,
          shadowUrl: null,
          iconAnchorRef: {
            x: ioptions.x,
            y: ioptions.y,
          },
          iconAnchorType: {
            x: ioptions.xunits,
            y: ioptions.yunits,
          },
        })
      }
      style['#' + e.getAttribute('id')] = options
    }
    return style
  },

  parseFolder: function (xml, style) {
    let el
    const layers = []
    let l
    el = xml.getElementsByTagName('Folder')
    for (let i = 0; i < el.length; i++) {
      if (!this._check_folder(el[i], xml)) {
        continue
      }
      l = this.parseFolder(el[i], style)
      if (l) {
        layers.push(l)
      }
    }
    el = xml.getElementsByTagName('Placemark')
    for (let j = 0; j < el.length; j++) {
      if (!this._check_folder(el[j], xml)) {
        continue
      }
      l = this.parsePlacemark(el[j], xml, style)
      if (l) {
        layers.push(l)
      }
    }
    if (!layers.length) {
      return
    }
    if (layers.length === 1) {
      return layers[0]
    }
    return new L.FeatureGroup(layers)
  },

  parsePlacemark: function (place, xml, style) {
    let i,
      j,
      el
    const options = {}
    el = place.getElementsByTagName('styleUrl')
    for (i = 0; i < el.length; i++) {
      const url = el[i].childNodes[0].nodeValue
      for (let a in style[url]) {
        options[a] = style[url][a]
      }
    }
    const layers = []

    const parse = ['LineString', 'Polygon', 'Point']
    for (j in parse) {

      const tag = parse[j]
      el = place.getElementsByTagName(tag)
      for (i = 0; i < el.length; i++) {
        const l = this['parse' + tag](el[i], xml, options)
        if (l) {
          layers.push(l)
        }
      }
    }

    if (!layers.length) {
      return
    }
    let layer = layers[0]
    if (layers.length > 1) {
      layer = new L.FeatureGroup(layers)
    }

    let name,
      descr = ''
    el = place.getElementsByTagName('name')
    if (el.length) {
      name = el[0].childNodes[0].nodeValue
    }
    el = place.getElementsByTagName('description')
    for (i = 0; i < el.length; i++) {
      for (j = 0; j < el[i].childNodes.length; j++) {
        descr = descr + el[i].childNodes[j].nodeValue
      }
    }

    if (name) {
      layer.bindPopup('<h2>' + name + '</h2>' + descr)
    }

    return layer
  },

  parseCoords: function (xml) {
    const el = xml.getElementsByTagName('coordinates')
    return this._read_coords(el[0])
  },

  parseLineString: function (line, xml, options) {
    const coords = this.parseCoords(line)
    if (!coords.length) {
      return
    }
    return new L.Polyline(coords, options)
  },

  parsePoint: function (line, xml, options) {
    const el = line.getElementsByTagName('coordinates')
    if (!el.length) {
      return
    }
    const ll = el[0].childNodes[0].nodeValue.split(',')
    return new L.KMLMarker(new L.LatLng(ll[1], ll[0]), options)
  },

  parsePolygon: function (line, xml, options) {
    let el,
      inner = [],
      coords
    const polys = []

    el = line.getElementsByTagName('outerBoundaryIs')
    for (let i = 0; i < el.length; i++) {
      coords = this.parseCoords(el[i])
      if (coords) {
        polys.push(coords)
      }
    }
    el = line.getElementsByTagName('innerBoundaryIs')
    for (let i = 0; i < el.length; i++) {
      coords = this.parseCoords(el[i])
      if (coords) {
        inner.push(coords)
      }
    }
    if (!polys.length) {
      return
    }
    if (options.fillColor) {
      options.fill = true
    }
    if (polys.length === 1) {
      return new L.Polygon(polys.concat(inner), options)
    }
    return new L.MultiPolygon(polys, options)
  },

  getLatLngs: function (xml) {
    const el = xml.getElementsByTagName('coordinates')
    let coords = []
    for (let j = 0; j < el.length; j++) {
      // text might span many childnodes
      coords = coords.concat(this._read_coords(el[j]))
    }
    return coords
  },

  _read_coords: function (el) {
    let text = '',
      coords = []
    for (let i = 0; i < el.childNodes.length; i++) {
      text = text + el.childNodes[i].nodeValue
    }
    text = text.split(/[\s\n]+/)
    for (let i = 0; i < text.length; i++) {
      const ll = text[i].split(',')
      if (ll.length < 2) {
        continue
      }
      coords.push(new L.LatLng(ll[1], ll[0]))
    }
    return coords
  },

})

L.KMLIcon = L.Icon.extend({

  createIcon: function () {
    const img = this._createIcon('icon')
    img.onload = function () {
      const i = new Image()
      i.src = this.src
      this.style.width = i.width + 'px'
      this.style.height = i.height + 'px'

      if (this.anchorType.x === 'UNITS_FRACTION' || this.anchorType.x === 'fraction') {
        img.style.marginLeft = (-this.anchor.x * i.width) + 'px'
      }
      if (this.anchorType.y === 'UNITS_FRACTION' || this.anchorType.x === 'fraction') {
        img.style.marginTop = (-(1 - this.anchor.y) * i.height) + 'px'
      }
      this.style.display = ''
    }
    return img
  },

  _setIconStyles: function (img, name) {
    L.Icon.prototype._setIconStyles.apply(this, [img, name])
    // save anchor information to the image
    img.anchor = this.options.iconAnchorRef
    img.anchorType = this.options.iconAnchorType
  },
})

L.KMLMarker = L.Marker.extend({
  options: {
    icon: new L.KMLIcon.Default()
  }
});
