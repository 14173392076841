import L from 'leaflet'
import { decodeContent } from '../../../utils'

export const apiUpdateMarker = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  let marker = self.searchMarker(mapID, options.key)

  //is there a map
  if (map && marker !== undefined) {
    //remove key
    delete options.key

    //set the new user options
    marker.setUserOptions(options)

    //update the icon
    if (options.icon !== undefined) {
      options.icon = {
        //defaults
        'iconUrl': null,
        'iconSize': null,
        'className': '',
        ...options.icon,
      }

      //empty icon, fallback to the default one
      if (options.icon.iconUrl !== '') {
        marker.setIcon(L.icon(options.icon))
      }
      else {
        marker.setIcon(new L.Icon.Default())
      }

      delete options.icon
    }

    //update tooltip
    if (options.tooltip !== undefined) {
      //set the tooltip
      self.updateTooltip(options.tooltip, marker)
      delete options.tooltip
    }

    //set the popup content
    if (options.popup !== undefined && options.popup.popupContent !== undefined) {

      if (marker.getPopup() === undefined) {
        //init the popup
        marker = self.initPopup(marker, options.popup)
      }
      else {
        const decodedContent = decodeContent(options.popup.popupContent);
        marker.getPopup().setContent(decodedContent)
      }

      delete options.popup.popupContent
    }

    return marker
  }
}
