//----------------------------------------------
//remove a google like overlay like the iframe
//----------------------------------------------
import { ReinosMaps } from '../../core'
import $ from 'domtastic'

ReinosMaps.prototype.removeOverlay = function (map, options) {
  map.overlay.enabled = false
  map.overlay.position = 'left'
  map.overlay.html = ''

  $(options.selector).find('#custom_maps_overlay').remove()
}
