import {
  apiRefresh,
  apiSetZoom,
  apiFitMap,
  apiCenter,
  apiRemoveMap,
  apiUpdateMap,
} from './map'
import {
  apiAddMarker,
  apiUpdateMarker,
  apiGetMarker,
  apiRemoveMarker,
  apiHideMarker,
  apiShowMarker,
  apiRemoveMarkers,
  apiHideMarkers,
  apiShowMarkers,
  apiMarkerClick,
  apiOpenMarkerPopup,
  apiCloseMarkerPopup,
  apiToggleMarkerPopup,
} from './marker'
import {
  apiAddCircle,
  apiGetCircle,
  apiRemoveCircle,
  apiUpdateCircle,
} from './circle'
import {
  apiAddRectangle,
  apiGetRectangle,
  apiRemoveRectangle,
  apiUpdateRectangle,
} from './rectangle'
import {
  apiAddPolygon,
  apiGetPolygon,
  apiRemovePolygon,
  apiUpdatePolygon,
} from './polygon'
import {
  apiAddPolyline,
  apiGetPolyline,
  apiRemovePolyline,
  apiUpdatePolyline,
} from './polyline'
import {
  apiAddGeoJson,
  apiAddKml,
  apiGeocode,
  apiGeoLocation,
} from './utils'
import { ReinosMaps } from '../../core'
import { apiZoomOut } from './map/zoomOut'
import { apiZoomIn } from './map/zoomIn'
import { throwError } from '../../utils'

ReinosMaps.prototype.api = function (type, options, callback) {
  const self = this

  //no type
  if (type === '') {
    throwError('Type not defined')
    return false
  }

  //options
  options = {
    mapID: '',
    key: '',
    ...options,
  }

  Object.entries(options).forEach(([index, v]) => {
    if (typeof v === 'string' && v.match('function:')) {
      const value = v
      options[index] = function () {
        eval(value.replace('function:', ''))
      }
    }
  })

  //set the vars
  let mapID
  let map

  //set a default return value
  let __returnValue

  //set the mapID
  if (options.mapID !== '') {
    //set the mapID
    mapID = options.mapID
    //delete options.mapID;

    //get the map
    map = self.getMap(mapID)
  }

  //default callback function
  const executeCallback = (callback) => {
    if (typeof callback === 'function') {
      callback()
    }
  }

  // log API Call in DEV mode
  if (self.isDevMode) {
    const stack = new Error()
    self.apiLog.add({
      method: type,
      options: { ...options },
      mapId: mapID,
      mapExists: !!map,
      code: `REINOS.api('${type}', ${JSON.stringify({ ...options })})`,
      stack: stack.stack,
    })
  }

  //what do we do
  switch (type) {

    //------------------------------------------------------------------------
    // Global map api
    //------------------------------------------------------------------------

    // refresh the map
    case 'refresh':
    case 'redraw':
      apiRefresh(map)
      break

    // set Zoom level
    case 'setZoom':
      //is there a map
      apiSetZoom(map, options)
      break

    // ZoomOut
    case 'zoomOut':
      //is there a map
      apiZoomOut(map, options)
      break

    // ZoomOut
    case 'zoomIn':
      //is there a map
      apiZoomIn(map, options)
      break

    // set Zoom level
    case 'fitMap':
      //is there a map
      apiFitMap(map, options)
      break

    // Center a map
    case 'center':
      //is there a map
      apiCenter(map, options)
      break

    // Get the map object
    case 'getMap':
      //is there a map
      if (map) {
        __returnValue = map
      }
      break

    // Get the map object
    case 'removeMap':
      __returnValue = apiRemoveMap(map, options)
      break

    // Update a map with new settings
    case 'updateMap':
      apiUpdateMap(map, options)
      break

    //------------------------------------------------------------------------
    // End Global map api
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Marker api
    //------------------------------------------------------------------------

    // add Marker, and return the marker numbers
    case 'addMarker':
      //is there a map
      __returnValue = apiAddMarker(map, options, mapID)
      break

    // Update a marker
    case 'updateMarker':
      __returnValue = apiUpdateMarker(map, options, mapID)
      break

    //Get the marker array
    case 'getAllMarkers':
      __returnValue = (self.markers)
      break

    //Get the marker array
    case 'getMarkers':
      __returnValue = (self.markers[mapID])
      break

    // Get the marker
    case 'getMarker':
      __returnValue = apiGetMarker(map, options, mapID)
      break

    //remove marker
    case 'removeMarker':
      apiRemoveMarker(map, options, mapID)
      break

    // hide existing Marker
    case 'hideMarker':
      apiHideMarker(map, options, mapID)
      break

    // show existing Marker
    case 'showMarker':
      apiShowMarker(map, options, mapID)
      break

    // Remove all markers
    case 'removeMarkers':
      apiRemoveMarkers(map, mapID)
      break

    // Hide all markers
    case 'hideMarkers':
      apiHideMarkers(map, mapID)
      break

    // Show all markers
    case 'showMarkers':
      apiShowMarkers(map, mapID)
      break

    //marker click
    case 'markerClick':
      apiMarkerClick(map, options, mapID)
      break

    //Open Marker Popup
    case 'openMarkerPopup':
      apiOpenMarkerPopup(options, mapID)
      break

    //Open Marker Popup
    case 'closeMarkerPopup':
      apiCloseMarkerPopup(options, mapID)
      break

    //Open Marker Popup
    case 'toggleMarkerPopup':
      apiToggleMarkerPopup(options, mapID)
      break

    //------------------------------------------------------------------------
    // End Marker api
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Layers
    //------------------------------------------------------------------------

    // Add a layer
    // case 'addLayer':
    //     //is there a map
    //     if(map) {
    //         if(options.layerName != undefined) {
    //             map.addLayer(options.layerName);
    //         }
    //     }
    //     break;

    // Remove a layer
    // case 'removeLayer':
    //     //is there a map
    //     if(map) {
    //         if(options.layerName != undefined) {
    //             map.removeLayer(options.layerName);
    //         }
    //     }
    //     break;

    //------------------------------------------------------------------------
    // End layers
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Circle api
    //------------------------------------------------------------------------

    // Create a circle
    case 'addCircle':
      __returnValue = apiAddCircle(map, options, mapID)
      break

    // Get the polygon
    case 'getCircle':
      __returnValue = apiGetCircle(map, options, mapID)
      break

    // update a polygon
    case 'updateCircle':
      apiUpdateCircle(map, options, mapID)
      break

    //remove polygon
    case 'removeCircle':
      apiRemoveCircle(map, options, mapID)
      break

    //------------------------------------------------------------------------
    // End circle api
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Rectangle api
    //------------------------------------------------------------------------

    // Create a Rectangle
    case 'addRectangle':
      __returnValue = apiAddRectangle(map, options, mapID)
      break

    // Get the polygon
    case 'getRectangle':
      __returnValue = apiGetRectangle(map, options, mapID)
      break

    // update a polygon
    case 'updateRectangle':
      apiUpdateRectangle(map, options, mapID)
      break

    //remove polygon
    case 'removeRectangle':
      apiRemoveRectangle(map, options, mapID)
      break

    //------------------------------------------------------------------------
    // End rectangle api
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Polygon api
    //------------------------------------------------------------------------

    // Create a Polygon
    case 'addPolygon':
      __returnValue = apiAddPolygon(map, options, mapID)
      break

    // Get the polygon
    case 'getPolygon':
      __returnValue = apiGetPolygon(map, options, mapID)
      break

    // update a polygon
    case 'updatePolygon':
      apiUpdatePolygon(map, options, mapID)
      break

    //remove polygon
    case 'removePolygon':
      apiRemovePolygon(map, options, mapID)
      break

    //------------------------------------------------------------------------
    // End polygon api
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Polyline api
    //------------------------------------------------------------------------

    // Create a Polyline
    case 'addPolyline':
      __returnValue = apiAddPolyline(map, options, mapID)
      break

    // Get the polyline
    case 'getPolyline':
      __returnValue = apiGetPolyline(map, options, mapID)
      break

    // Get the polyline
    case 'updatePolyline':
      apiUpdatePolyline(map, options, mapID)
      break

    //remove marker
    case 'removePolyline':
      apiRemovePolyline(map, options, mapID)
      break

    //------------------------------------------------------------------------
    // End polyline api
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // geoJson api
    //------------------------------------------------------------------------
    case 'addGeojson' :
      apiAddGeoJson(map, options)
      break
    //------------------------------------------------------------------------
    // END geoJson api
    //------------------------------------------------------------------------
    //------------------------------------------------------------------------
    // KML
    //------------------------------------------------------------------------
    case 'addKml' :
      apiAddKml(map, options)
      break

    //------------------------------------------------------------------------
    // KML
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    // Geolocation api, only working for SSL sites
    //------------------------------------------------------------------------
    case 'geolocation' :
      apiGeoLocation(options)
      break

    //------------------------------------------------------------------------
    // Geocode api
    //------------------------------------------------------------------------

    //
    // Geocode using the API way to cache all addresses
    case 'geocode':
      apiGeocode(options)
      break

    //------------------------------------------------------------------------
    // End geocode api
    //------------------------------------------------------------------------

    default :
      throwError(`unknown method: "${type}"`)
      break
  }

  executeCallback(callback)

  return __returnValue
}

// aliases
ReinosMaps.prototype.trigger = ReinosMaps.prototype.api
ReinosMaps.prototype.triggerEvent = ReinosMaps.prototype.api
