import { base64Decode } from '../utils'
import L from 'leaflet'
import 'leaflet.gridlayer.googlemutant'
import 'leaflet.mapkitmutant'
import { ReinosMaps } from '../core'
import { addAppleMapsTile, addGoogleTile, addTile } from './tiles'

ReinosMaps.prototype.addTiles = function (map, tilesObject, google_styled_map, tile_control_position) {
  // decode back
  tilesObject = JSON.parse(base64Decode(tilesObject))

  google_styled_map = google_styled_map || null
  tile_control_position = tile_control_position || 'topright'

  const tiles = {}
  tilesObject.forEach((tile, index) => {
    let tileLayer = {}

    if (tile.name.match('google')) {
      tileLayer = addGoogleTile(tile, google_styled_map)
    }
    else if (tile.name.match('apple')) {
      tileLayer = addAppleMapsTile(tile)
    }
    else {
      tile.options['apikey'] = tile.api_key
      tileLayer = addTile(tile)
    }

    if (index === 0) {
      const minZoom = map.raw_options.min_zoom ? map.raw_options.min_zoom : tileLayer.options.minZoom
      const maxZoom = map.raw_options.max_zoom ? map.raw_options.max_zoom : tileLayer.options.maxZoom

      map.map.addLayer(tileLayer)
      map.map.setMinZoom(minZoom)
      map.map.setMaxZoom(maxZoom)
    }

    tiles[tileLayer.label] = tileLayer
  })

  //save the tiles
  map.tiles = tiles
  map.tilesPosition = Object.keys(tiles).map(tileName => tileName)

  //add the tiles layers as a group if there are more then 1
  map.tileControl = null
  if (tilesObject.length > 1) {
    map.tileControl = L.control.layers(tiles, {}, {
      position: tile_control_position,
    }).addTo(map.map)

    map.map.on('baselayerchange', function (e) {
      const minZoom = map.raw_options.min_zoom ? map.raw_options.min_zoom : e.layer.options.minZoom
      const maxZoom = map.raw_options.max_zoom ? map.raw_options.max_zoom : e.layer.options.maxZoom

      map.map.setMinZoom(minZoom)
      map.map.setMaxZoom(maxZoom)
    })
  }
}
