import L from 'leaflet'
import { catchEvents, executeEvent } from '../../../utils'

export const apiAddRectangle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    //merge default settings with given settings
    options = {
      'stroke': true,
      'color': '#3388ff',
      'weight': 3,
      'opacity': 1.0,
      'fill': true,
      'fillColor': '#3388ff',
      'fillOpacity': 0.2,
      'className': 'maps_rectangle',
      'tooltip': {},
      popup: {},
      ...options,
    }

    const { events, options: optionsWithoutEvents } = catchEvents(options)
    options = optionsWithoutEvents

    //set the user options
    const userOptions = { ...options }

    const popupOptions = options.popup
    delete options.popup

    //reset the tooltip options
    const tooltipOptions = options.tooltip
    delete options.tooltip

    const bounds = options.bounds
    delete options.bounds

    //add bound
    map.bounds.rectangles.push([
      bounds[0][0],
      bounds[0][1],
    ], [
      bounds[1][0],
      bounds[1][1],
    ])

    const keys = typeof options.keys === 'string' ? options.keys : ''
    let new_rectangle = L.rectangle(bounds, options).addTo(map.map)

    //custom property and method to handle the userOptions
    new_rectangle.userOptions = userOptions
    new_rectangle.setUserOptions = function (newUserOptions) {
      this.userOptions = { ...this.userOptions, ...newUserOptions }
    }

    //set the tooltip
    self.addTooltip(tooltipOptions, new_rectangle)

    // assign the events to the feature
    executeEvent(events, new_rectangle);

    //init the popup
    new_rectangle = self.initPopup(new_rectangle, popupOptions)

    //set the return value
    const __returnValue = self.saveRectangle(mapID, new_rectangle, keys)

    //fit map
    self.fitBounds(map)

    return __returnValue
  }
}
