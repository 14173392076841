export const apiMarkerClick = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const marker = self.searchMarker(mapID, options.key)

  //trigger the click
  if (marker !== undefined) {

    //trigger
    marker.fire('click')

    //callback
    if (typeof options.callback === 'function') {
      options.callback(map.map, marker)
    }
  }
}
