//----------------------------------------------
//save single marker
//----------------------------------------------
import { createUUID, replaceAll } from '../../utils'
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.saveMarker = function (mapID, marker, keys) {
  return this.saveFeature(mapID, 'marker', marker, keys)
}

ReinosMaps.prototype.saveCircle = function (mapID, circle, keys) {
  return this.saveFeature(mapID, 'circle', circle, keys)
}

ReinosMaps.prototype.saveRectangle = function (mapID, rectangle, keys) {
  return this.saveFeature(mapID, 'rectangle', rectangle, keys)
}

ReinosMaps.prototype.savePolyline = function (mapID, polyline, keys) {
  return this.saveFeature(mapID, 'polyline', polyline, keys)
}

ReinosMaps.prototype.savePolygon = function (mapID, polygon, keys) {
  return this.saveFeature(mapID, 'polygon', polygon, keys)
}

ReinosMaps.prototype.saveFeature = function (mapID, typeKey, featureItem, keys) {
  const self = this

  // Make it singular or plural
  const singularTypeKey = typeKey.replace(/s$/, '')
  const pluralTypeKey = singularTypeKey.slice() + 's'

  let cachedItems = self[pluralTypeKey].get(mapID)
  if (cachedItems === undefined) {
    cachedItems = []
    self[pluralTypeKey].set(mapID, cachedItems)
  }

  //set markerNumber
  featureItem[`${singularTypeKey}Number`] = cachedItems.length + 1

  //set the uuid
  featureItem[`${singularTypeKey}UUID`] = createUUID()

  //set the search keys
  const searchKeys = new Set([
    featureItem[`${singularTypeKey}Number`],
    featureItem[`${singularTypeKey}UUID`],
  ])

  //add the extra keys
  if (typeof keys === 'string') {
    const _keys = keys.split(':')

    _keys.forEach(_key => {
      _key = _key.trim()
      searchKeys.add(_key)
      searchKeys.add(_key.toLowerCase())
      searchKeys.add(replaceAll(_key, ' ', '_'))
      searchKeys.add(replaceAll(_key, ' ', '_').toLowerCase())
      searchKeys.add(replaceAll(_key, ' ', '-'))
      searchKeys.add(replaceAll(_key, ' ', '-').toLowerCase())
    })
  }

  //set the arrays
  cachedItems.push({
    keys: searchKeys,
    [singularTypeKey]: featureItem,
  })

  // save the markers
  self[pluralTypeKey].set(mapID, cachedItems)

  //update latlngs collection
  if (singularTypeKey === 'marker') {
    const latlng = `${featureItem.getLatLng().lat},${featureItem.getLatLng().lng}`
    self.latlngs.add(latlng)
  }

  return featureItem
}
