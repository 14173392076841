export const apiRemoveCircle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const circle = self.searchCircle(mapID, options.key)

  //is there a map
  if (map && circle !== undefined) {
    // remove from map
    circle.remove()

    //remove from the cache
    self.removeCircle(mapID, options.key)

    //remove from the bounds
    map.bounds.circles = map.bounds.circles.filter(
      value => circle.getLatLng().lat !== value[0] && circle.getLatLng().lng !== value[1])
  }
}
