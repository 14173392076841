import L from 'leaflet'
import '../../../libs/leaflet-kml'

export const apiAddKml = (map, options) => {
  const kmlLayer = new L.KML(options.url)

  kmlLayer.on('loaded', function (e) {
    map.map.fitBounds(e.target.getBounds())
  })

  map.map.addLayer(kmlLayer)
}
