import CRS from 'proj4leaflet'
import L from 'leaflet'

export const crsCollection = new Map()

// This is the important task, where we set the map projection to EPSG:3006
crsCollection.set('EPSG:3006', new CRS.CRS('EPSG:3006',
  '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  {
    resolutions: [
      4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8,
    ],
    origin: [-1200000.000000, 8500000.000000],
    bounds: L.bounds([-1200000.000000, 8500000.000000], [4305696.000000, 2994304.000000]),
  }),
)

crsCollection.set('default', L.CRS.EPSG3857)
