//----------------------------------------------
// GeoLocate function
//----------------------------------------------
import { ReinosMaps } from '../core'

ReinosMaps.prototype.geolocate = function (options) {
  const complete_callback = options.always || options.complete
  const error_callback = options.error || function () {}
  const success_callback = options.success || function () {}

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      success_callback(position)

      if (complete_callback) {
        complete_callback()
      }
    }, function (error) {
      error_callback(error)

      if (complete_callback) {
        complete_callback()
      }
    }, options.options)
  }
  else {

    if (complete_callback) {
      complete_callback()
    }
  }
}
