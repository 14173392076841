import { crsCollection } from './utils'

export class ReinosMaps {
  author_url = 'https://addons.reinos.nl/maps/'

  def = {
    lat: 34.5133,
    lng: -94.1629,
    circle: {
      'fit_circle': true,
      'stroke_color': '#BBD8E9',
      'stroke_opacity': 1,
      'stroke_weight': 3,
      'fill_color': '#BBD8E9',
      'fill_opacity': 0.6,
      'radius': 1000,
    },
  }

  maps = new Map()

  positions = ['topleft', 'topright', 'bottomleft', 'bottomright']

  //save the fire function that already have fired
  _firedEvents = {}

  latlngs = new Set()

  // features
  markers = new Map()
  polygons = new Map()
  polylines = new Map()
  rectangles = new Map()
  circles = new Map()

  // hold the log of all API calls
  apiLog = new Set()

  crs = crsCollection

  //hold the Style function for the GEOJSON
  _geojson = {
    style: {},
  }

  leaflet = window.L

  setConfig (options = {}) {
    ({
      version: this.version = '0.0.0',
      api_path: this.api_path,
      theme_path: this.theme_path,
      dev_mode: this.isDevMode = false,
    } = options)

    this.isDevMode = this.isDevMode === '1' || location.hash === '#maps_debug'
  }
}
