import { decodeContent } from '../../../utils'

export const apiUpdatePolyline = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  let polyline = self.searchPolyline(mapID, options.key)

  if (map && polyline !== undefined) {
    //remove key
    delete options.key

    //set the new user options
    polyline.setUserOptions(options)

    //update tooltip
    if (options.tooltip !== undefined) {
      //set the tooltip
      self.updateTooltip(options.tooltip, polyline)
      delete options.tooltip
    }

    //if latlngs is found in the options, use that for redraw the line
    if (typeof options.latlngs !== 'undefined') {
      polyline.setLatLngs(options.latlngs)
      delete options.latlngs
    }

    //set the new options
    polyline.setStyle(options)

    //set the popup content
    if (options.popup !== undefined && options.popup.popupContent !== undefined) {

      if (polyline.getPopup() === undefined) {
        //init the popup
        polyline = self.initPopup(polyline, options.popup)
      }
      else {
        const decodedContent = decodeContent(options.popup.popupContent)
        polyline.getPopup().setContent(decodedContent)
      }

      delete options.popup.popupContent
    }

    return polyline
  }
}
