//----------------------------------------------
//remove single marker
//----------------------------------------------
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.removeMarker = function (mapID, key) {
  return this.removeFeature(mapID, 'marker', key)
}

ReinosMaps.prototype.removeCircle = function (mapID, key) {
  return this.removeFeature(mapID, 'circle', key)
}

ReinosMaps.prototype.removeRectangle = function (mapID, key) {
  return this.removeFeature(mapID, 'rectangle', key)
}

ReinosMaps.prototype.removePolyline = function (mapID, key) {
  return this.removeFeature(mapID, 'polyline', key)
}

ReinosMaps.prototype.removePolygon = function (mapID, key) {
  return this.removeFeature(mapID, 'polygon', key)
}

ReinosMaps.prototype.removeFeature = function (mapID, typeKey, key) {
  const self = this

  // Make it singular or plural
  const singularTypeKey = typeKey.replace(/s$/, '')
  const pluralTypeKey = singularTypeKey.slice() + 's'

  let cachedItems = self[pluralTypeKey].get(mapID)

  if (cachedItems !== undefined) {
    for (const [index, item] of cachedItems.entries()) {
      if (item.keys.has(key)) {
        // Remove the item
        cachedItems.splice(index, 1)

        // update the number
        cachedItems = cachedItems.map(cachedItem => {
          cachedItem[singularTypeKey][`${singularTypeKey}Number`] = cachedItems.length
          return cachedItem
        })

        // update the list of items
        self[pluralTypeKey].set(mapID, cachedItems)

        // remove latlng from the cached Set()
        if (singularTypeKey === 'marker') {
          const latlng = `${item[singularTypeKey].getLatLng().lat},${item[singularTypeKey].getLatLng().lng}`
          self.latlngs.delete(latlng)
        }
      }
    }
  }
}
