import L from 'leaflet'

export const addTile = (tile) => {
  const options = tile.options || {}
  const url = tile.url || ''

  const tileLayer = L.tileLayer(url, options)

  tileLayer.name = tile.name
  tileLayer.label = tile.label || tile.name

  return tileLayer
}
