import L from 'leaflet'
import { catchEvents, executeEvent } from '../../../utils'

export const apiAddPolygon = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    //merge default settings with given settings
    options = {
      'stroke': true,
      'color': '#3388ff',
      'weight': 3,
      'opacity': 1.0,
      'fill': true,
      'fillColor': '#3388ff',
      'fillOpacity': 0.2,
      'className': 'maps_rectangle',
      'tooltip': {},
      popup: {},
      ...options,
    }

    const { events, options: optionsWithoutEvents } = catchEvents(options)
    options = optionsWithoutEvents

    //set the user options
    const userOptions = { ...options }

    const popupOptions = options.popup
    delete options.popup

    //reset the tooltip options
    const tooltipOptions = options.tooltip
    delete options.tooltip

    const latlngs = options.latlngs
    delete options.latlngs

    //add bound
    latlngs.forEach(latlng => map.bounds.polygons.push([latlng[0], latlng[1]]))

    const keys = typeof options.keys == 'string' ? options.keys : ''
    let new_polygon = L.polygon(latlngs, options).addTo(map.map)

    //custom property and method to handle the userOptions
    new_polygon.userOptions = userOptions
    new_polygon.setUserOptions = function (newUserOptions) {
      this.userOptions = { ...this.userOptions, ...newUserOptions }
    }

    //set the tooltip
    self.addTooltip(tooltipOptions, new_polygon)

    // assign the events to the feature
    executeEvent(events, new_polygon);

    //init the popup
    new_polygon = self.initPopup(new_polygon, popupOptions)

    //set the return value
    const __returnValue = self.savePolygon(mapID, new_polygon, keys)

    //fit map
    self.fitBounds(map)

    return __returnValue
  }
}
