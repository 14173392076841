//----------------------------------------------
//add and update an tooltip
//----------------------------------------------
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.addTooltip = function (options, feature) {
  //merge default icon settings with given settings
  options = {
    //defaults
    'direction': 'auto',
    'permanent': false,
    'sticky': false,
    'content': '',
    ...options,
  }

  //set the tooltip
  if (options.content && options.content !== '') {
    const content = options.content
    delete options.content
    feature.bindTooltip(content, options)
  }
  else {
    feature.unbindTooltip()
  }
}
