//----------------------------------------------
//set the markers to the arrays
//----------------------------------------------
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.searchMarker = function (mapID, key, multiple) {
  return this.searchFeature(mapID, 'marker', key, multiple)
}

ReinosMaps.prototype.searchCircle = function (mapID, key, multiple) {
  return this.searchFeature(mapID, 'circle', key, multiple)
}

ReinosMaps.prototype.searchRectangle = function (mapID, key, multiple) {
  return this.searchFeature(mapID, 'rectangle', key, multiple)
}

ReinosMaps.prototype.searchPolyline = function (mapID, key, multiple) {
  return this.searchFeature(mapID, 'polyline', key, multiple)
}

ReinosMaps.prototype.searchPolygon = function (mapID, key, multiple) {
  return this.searchFeature(mapID, 'polygon', key, multiple)
}

ReinosMaps.prototype.searchFeature = function (mapID, typeKey, key, multiple) {
  multiple = multiple || false

  const self = this
  const returnItems = []

  // Make it singular or plural
  const singularTypeKey = typeKey.replace(/s$/, '')
  const pluralTypeKey = singularTypeKey.slice() + 's'

  //loop over the markers
  const cachedItems = self[pluralTypeKey].get(mapID)
  if (cachedItems !== undefined) {
    for (const cachedItem of cachedItems) {
      if (cachedItem.keys.has(key)) {
        returnItems.push(cachedItem[singularTypeKey])

        if (!multiple) {
          break
        }
      }
    }
  }

  if (!multiple) {
    return returnItems.shift()
  }

  return returnItems
}
