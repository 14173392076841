export const setInfoWindowUrl = (marker_object, type) => {
  let url = ''
  if (marker_object !== undefined) {
    switch (type) {
      case 'route_to':
        url = 'https://maps.google.com/maps?daddr=' + marker_object.lat() + ',' + marker_object.lng()
        //http://maps.google.com/maps?saddr=start&daddr=end
        break

      case 'route_from':
        url = 'https://maps.google.com/maps?saddr=' + marker_object.lat() + ',' + marker_object.lng()
        //http://maps.google.com/maps?saddr=start&daddr=end
        break

      default:
      case 'map':
        url = 'https://maps.google.com/maps?q=' + marker_object.lat() + ',' + marker_object.lng()
        //https://maps.google.com/maps?q=
        break
    }
  }
  return url
}
