import { ReinosMaps } from '../../core'

ReinosMaps.prototype.getMap = function (id) {
  const cachedMap = this.maps.get(id)

  if (cachedMap !== undefined) {
    return cachedMap
  }

  return false
}
