//----------------------------------------------
//save marker data to the prepare array
//----------------------------------------------
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.prepareMapData = function (type, data) {
  const self = this

  //prepare holders, we save the data first to this array so we can do a bulk add
  if (self._prepareMapData === undefined) {
    this._prepareMapData = {}
  }

  //check the type name and make sure we add a S to the end
  // if(type.slice(-1) !== 's') {
  // 	type = type + 's';
  // }
  //make sure we have a defined object
  if (self._prepareMapData[type] === undefined) {
    self._prepareMapData[type] = []
  }

  //save it
  self._prepareMapData[type].push(data)
}
