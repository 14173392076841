export const apiRemoveMap = (map, options) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    self.maps.delete(options.mapID)
    self.polygons.delete(options.mapID)
    self.polylines.delete(options.mapID)
    self.rectangles.delete(options.mapID)
    self.circles.delete(options.mapID)
    self.markers.delete(options.mapID)

    return map
  }
}
