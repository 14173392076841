export const apiGetPolyline = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const polyline = self.searchPolyline(mapID, options.key)

  if (map && polyline !== undefined) {
    return polyline
  }
}
