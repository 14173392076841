export const apiShowMarker = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const marker = self.searchMarker(mapID, options.key)

  if (map && marker !== undefined) {
    marker._icon.style.display = 'block'
    marker._shadow.style.display = 'block'
  }
}
