import { replaceAll } from '../../utils/replaceAll'
import { setInfoWindowUrl } from '../../utils/setInfowindowUrl'
import L from 'leaflet'
import { ReinosMaps } from '../../core'
import $ from 'domtastic'

//----------------------------------------------
//add a google like overlay like the iframe
//----------------------------------------------
ReinosMaps.prototype.addOverlay = function (map, options) {

  let latlng
  let marker_object

  //default
  map.overlay = {}
  map.overlay.enabled = false
  map.overlay.position = options.overlay_position !== '' ? options.overlay_position : 'left'
  map.overlay.html = options.overlay_html !== '' ? options.overlay_html : ''

  //do the checks
  if (options.latlng !== undefined && options.latlng[0] !== undefined) {
    latlng = options.latlng[0].split(',')
    marker_object = L.latLng(latlng[0], latlng[1])

    map.overlay.html = replaceAll(map.overlay.html, '[route_to_url]', setInfoWindowUrl(marker_object, 'route_to'))
    map.overlay.html = replaceAll(map.overlay.html, '[route_from_url]', setInfoWindowUrl(marker_object, 'route_from'))
    map.overlay.html = replaceAll(map.overlay.html, '[map_url]', setInfoWindowUrl(marker_object, 'map'))
  }

  if (map.overlay.html !== '') {
    if ($(options.selector).find('#custom_maps_overlay').length === 0) {
      const style = `margin: 10px;
      padding: 1px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
      box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: white;`
      const html = `
        <div class="google-like-overlay-position" style="z-index:999;position: absolute; ${map.overlay.position}: 0; top: 0;">
          <div style="${style}" id="custom_maps_overlay">
            <div style="padding:5px;" class="place-card google-like-overlay-content place-card-large">${map.overlay.html}</div>
          </div>
        </div>`
      $(map.raw_options.selector).prepend(html)
    }

    map.overlay.enabled = true
  }
}
