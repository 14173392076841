import L from 'leaflet'
import 'leaflet.icon.glyph'
import { catchEvents, executeEvent } from '../../../utils'

export const apiAddMarker = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {

    //merge default settings with given settings
    options = {
      //defaults
      'icon': null,
      'shadow': null,
      'draggable': false,
      'autoPan': false,
      'autoPanPadding': L.point(50, 50),
      //'autoPanSpeed': 10,
      'keyboard': true,
      'title': '',
      'alt': '',
      'zIndexOffset': 0,
      'opacity': 1,
      'riseOnHover': false,
      'riseOffset': 250,
      'pane': 'markerPane',
      'bubblingMouseEvents': false,
      'tooltip': {},
      'glyph': {},
      'assign': {},

      //bounce options from https://github.com/maximeh/leaflet.bouncemarker
      // 'bounceOnAdd' : false,

      //other
      'lat': null,
      'lng': null,
      'fitBounds': true,
      popup: {},
      ...options,
    }

    //check for for a valid latlng
    if (options.lat === null || options.lat === '' || options.lng === null || options.lng === '') {
      console.error('Maps Error: Lat and/or Lng coordinate is invalid')
      return
    }

    const { events, options: optionsWithoutEvents } = catchEvents(options)
    options = optionsWithoutEvents

    //set the user options
    const userOptions = { ...options }

    const popupOptions = options.popup
    delete options.popup

    //set the assign data for assign to the marker object
    const assignData = options.assign
    delete options.assign

    //reset the tooltip options
    const tooltipOptions = options.tooltip
    delete options.tooltip

    //set the default options from the default object
    const iconDefault = { ...L.Icon.Default.prototype.options }
    //reset some values
    iconDefault.iconUrl = null
    iconDefault.iconRetinaUrl = null
    iconDefault.shadowUrl = null

    //merge default icon settings with given settings
    options.icon = { ...iconDefault, ...options.icon }

    //check if we need to use the default icon? of if Glyph icon is enabled
    if (options.glyph.enabled !== undefined && options.glyph.enabled) {
      options.icon = L.icon.glyph({
        prefix: options.glyph.prefix,
        glyph: options.glyph.name,
        className: options.glyph.className,
        glyphColor: options.glyph.color,
        iconUrl: self.theme_path + 'dist/images/glyph-marker-icon.svg',
      })
    }
    else if (options.icon.iconUrl === null || options.icon.iconUrl === '') {
      //remove the default icon
      delete options.icon.iconUrl
      delete options.icon.iconRetinaUrl
      delete options.icon.shadowUrl
      // //set some defaults to the default icon
      L.Icon.Default.prototype.options = { ...L.Icon.Default.prototype.options, ...options.icon }
      //init the default icon
      options.icon = new L.Icon.Default()
    }
    else {
      options.icon = L.icon(options.icon)
    }

    //get the keys
    const keys = typeof options.keys === 'string' ? options.keys : ''

    //init a marker class
    let marker = L.marker([options.lat, options.lng], options)

    //assign the assignData to the marker
    Object.entries(assignData).forEach(([key, val]) => {
      if (marker.extra === undefined) {
        marker.extra = {}
      }

      //set the data
      marker['extra'][key] = val
    })

    //custom property and method to handle the userOptions
    marker.userOptions = userOptions
    marker.setUserOptions = function (newUserOptions) {
      this.userOptions = { ...this.userOptions, ...newUserOptions }
    }

    //set the tooltip
    self.addTooltip(tooltipOptions, marker)

    // assign the events to the feature
    executeEvent(events, marker);

    //check if we have to deal with a marker cluster?
    if (map.marker_cluster.enabled) {
      map.marker_cluster.markers.addLayer(marker)
      map.map.addLayer(map.marker_cluster.markers)
    }
    else {
      marker.addTo(map.map)
    }

    //add bound
    map.bounds.markers.push([
      marker.getLatLng().lat,
      marker.getLatLng().lng,
    ])

    //init the popup
    marker = self.initPopup(marker, popupOptions)

    self.saveMarker(mapID, marker, keys)

    //fit map
    if (options.fitBounds) {
      self.fitBounds(map)
    }

    //open by default?
    if (typeof options.open_by_default === 'boolean' && options.open_by_default) {
      marker.openPopup()
    }

    //callback
    if (options.callback && typeof (options.callback) === 'function') {
      setTimeout(function () {
        options.callback()
      }, 200)
    }

    return marker
  }
}
