export const apiRemoveRectangle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  const rectangle = self.searchRectangle(mapID, options.key)

  //is there a map
  if (map && rectangle !== undefined) {
    rectangle.remove()

    //remove from the cache
    self.removeRectangle(mapID, options.key)

    //remove from the bounds
    const northEast = rectangle.getBounds().getNorthEast()
    const southWest = rectangle.getBounds().getSouthWest()

    map.bounds.rectangles = map.bounds.rectangles.filter(
      value => (northEast.lat !== value[0] && northEast.lng !== value[1])
        || (southWest.lat !== value[0] && southWest.lng !== value[1]),
    )
  }
}
