export const apiShowMarkers = (map, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS
  const cachedMarkers = self.markers.get(mapID)

  if (map && cachedMarkers !== undefined) {
    cachedMarkers.forEach(({ marker }) => {
      marker._icon.style.display = 'block'
      marker._icon.style.display = 'block'
    })
  }
}
