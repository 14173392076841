export const apiGetPolygon = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  //get the marker
  const polygon = self.searchPolygon(mapID, options.key)

  //is there a map
  if (map && polygon !== undefined) {
    return polygon
  }
}
