//----------------------------------------------
//run through the saved prepared markers to add them to the map
//----------------------------------------------
import { ReinosMaps } from '../../core'

ReinosMaps.prototype.runPreparedMapData = function (typeMapData) {
  const self = this

  let prepareMapData = {}

  //if we call this function with a type, just run that one
  if (typeMapData !== undefined && self._prepareMapData[typeMapData] !== undefined) {

    //get the data for the specific type
    prepareMapData[typeMapData] = self._prepareMapData[typeMapData]

    //no result, run through all of the prepared items
  }
  else {
    prepareMapData = self._prepareMapData
  }

  //loop over the types
  Object.entries(prepareMapData).forEach(([type, data]) => {

    //run the beforeReady event
    if (self._firedEvents[type] === undefined) {
      self._firedEvents[type + '.beforeReady'] = true
      self.fire(type + '.beforeReady')
    }

    //any result in the array
    if (data.length > 0) {
      //the method name should be have a uppercase
      let method = 'add' + type.charAt(0).toUpperCase() + type.slice(1)

      //make sure if we have an ending S, we remove it
      if (method.slice(-1) === 's') {
        method = method.slice(0, -1)
      }

      //get the first one and delete it from the root array
      const mapData = data.shift()

      //call the api
      self.api(method, mapData, function () {
        // in the callback we call again, so we can loop over all the data objects
        self.runPreparedMapData(type)
      })

      //no result, run the callback for the ready.type
    }
    else {
      //make sure we call it only once
      if (self._firedEvents[type] === undefined) {
        self._firedEvents[type + '.ready'] = true
        setTimeout(() => self.fire(type + '.ready'), 100)
      }
    }
  })
}
