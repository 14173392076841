import L from 'leaflet'
import 'leaflet.mapkitmutant'
import { throwError } from '../../utils'

export const addAppleMapsTile = (tile) => {
  if (tile.api_key === '') {
    throwError('Missing Api Key. Please enter a correct API Key in your Maps cp.')
  }

  const tileLayer = new L.MapkitMutant({
    type: tile.name.replace('apple_maps_', ''),
    authorizationCallback: function (done) {
      done(tile.api_key)
    },
    language: 'en',
    debugRectangle: false,
  })

  tileLayer.name = tile.name
  tileLayer.label = tile.label || tile.name

  return tileLayer
}
