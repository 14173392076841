//set the geoJSON style
import { ReinosMaps } from '../core'

ReinosMaps.prototype.setGeoJsonStyle = function (style) {
  if (typeof style === 'function') {
    this._geojson.style = style
  }
  else {
    throw new Error('Given parameter for style is not a function')
  }
}
