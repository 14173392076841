import L from 'leaflet'
import { catchEvents, executeEvent } from '../../../utils'

export const apiAddCircle = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    //merge default settings with given settings
    options = {
      'radius': 0,
      'stroke': true,
      'color': '#3388ff',
      'weight': 3,
      'opacity': 1.0,
      'fill': true,
      'fillColor': '#3388ff',
      'fillOpacity': 0.2,
      'className': 'maps_circle',
      'tooltip': {},
      popup: {},
      ...options,
    }

    const { events, options: optionsWithoutEvents } = catchEvents(options)
    options = optionsWithoutEvents

    //set the user options
    const userOptions = { ...options }

    const popupOptions = options.popup
    delete options.popup

    //add bound
    map.bounds.circles.push([
      options.lat,
      options.lng,
    ])

    //reset the tooltip options
    const tooltipOptions = options.tooltip
    delete options.tooltip

    //set the keys
    const keys = typeof options.keys === 'string' ? options.keys : ''

    //add circle to the map
    let new_circle = L.circle([options.lat, options.lng], options).addTo(map.map)

    //custom property and method to handle the userOptions
    new_circle.userOptions = userOptions
    new_circle.setUserOptions = function (newUserOptions) {
      this.userOptions = { ...this.userOptions, ...newUserOptions }
    }

    //set the tooltip
    self.addTooltip(tooltipOptions, new_circle)

    // assign the events to the feature
    executeEvent(events, new_circle)

    //init the popup
    new_circle = self.initPopup(new_circle, popupOptions)

    //save to the cache
    const __returnValue = self.saveCircle(mapID, new_circle, keys)

    //fit map
    self.fitBounds(map)

    return __returnValue
  }
}
