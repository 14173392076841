//Fit the Bounds
import { ReinosMaps } from '../core'

ReinosMaps.prototype.fitBounds = function (map) {
  let allBounds = []

  if (map.fit.markers) {
    allBounds = [
      ...allBounds,
      ...map.bounds.markers,
    ]
  }
  if (map.fit.circles) {
    allBounds = [
      ...allBounds,
      ...map.bounds.circles,
    ]
  }
  if (map.fit.rectangles) {
    allBounds = [
      ...allBounds,
      ...map.bounds.rectangles,
    ]
  }
  if (map.fit.polylines) {
    allBounds = [
      ...allBounds,
      ...map.bounds.polylines,
    ]
  }
  if (map.fit.polygons) {
    allBounds = [
      ...allBounds,
      ...map.bounds.polygons,
    ]
  }
  if (map.fit.geojson) {
    allBounds = [
      ...allBounds,
      ...map.bounds.geojson,
    ]
  }
  //routes are polylines, so fit the bounds on that
  if (map.fit.routes) {
    allBounds = [
      ...allBounds,
      ...map.bounds.polylines,
    ]
  }

  if (allBounds.length > 0) {
    map.map.fitBounds(allBounds)

    setTimeout(() => {
      // input to zoom out per level
      if (map.fit.forceZoomOut > 0) {
        map.map.zoomOut(map.fit.forceZoomOut)
      }
      // input to forceZoom on a level
      else if (map.fit.forceZoom > 0) {
        map.map.setZoom(map.fit.forceZoom)
      }
    }, 400);
  }
}
