import L from 'leaflet'
import { catchEvents, executeEvent } from '../../../utils'

export const apiAddPolyline = (map, options, mapID) => {
  // eslint-disable-next-line
  const self = REINOS_MAPS

  if (map) {
    //merge default settings with given settings
    options = {
      'stroke': true,
      'color': '#3388ff',
      'weight': 3,
      'opacity': 1.0,
      'className': 'maps_rectangle',
      'tooltip': {},
      popup: {},
      ...options,
    }

    const { events, options: optionsWithoutEvents } = catchEvents(options)
    options = optionsWithoutEvents

    //set the user options
    const userOptions = { ...options }

    const popupOptions = options.popup
    delete options.popup

    //reset the tooltip options
    const tooltipOptions = options.tooltip
    delete options.tooltip

    const latlngs = options.latlngs
    delete options.latlngs

    //add bound
    latlngs.forEach(v => map.bounds.polylines.push([v[0], v[1]]))

    const keys = typeof options.keys === 'string' ? options.keys : ''
    let new_polyline = L.polyline(latlngs, options).addTo(map.map)

    //custom property and method to handle the userOptions
    new_polyline.userOptions = userOptions
    new_polyline.setUserOptions = function (newUserOptions) {
      this.userOptions = { ...this.userOptions, ...newUserOptions }
    }

    //set the tooltip
    self.addTooltip(tooltipOptions, new_polyline)

    // assign the events to the feature
    executeEvent(events, new_polyline);

    //init the popup
    new_polyline = self.initPopup(new_polyline, popupOptions)

    //set the return value
    const __returnValue = self.savePolyline(mapID, new_polyline, keys)

    //fit map
    self.fitBounds(map)

    return __returnValue
  }
}
